@media screen and (min-width: 344px) {
  #display-title {
    h1 {
      font-size: 2rem;
    }
    h3 {
      font-size: 1.6rem;
      text-align: center;
    }
  }
}

@media screen and (min-width: 768px) {
  #display-title {
    h1 {
      font-size: 2.4rem;
    }
    h3 {
      font-size: 2rem;
    }
  }
}

@media screen and (min-width: 991px) {
  #display-title {
    h1 {
      font-size: 3rem;
      padding: 1rem;
    }
  }
}
