@media screen and (min-width: 344px) {
  #stateDestinationMap {
    button[type="button"] {
      position: "absolute";
      top: -15;
      right: 15;
    }
    .dropdown {
      ul {
        li {
          padding: 0;
          padding: 0.75rem 0;
        }
      }
    }
  }
}
